* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
}

:root {
  --primary: #002367;
  --secondary: #154d2a;
  --text: #595959;
  --background-gray: #f7f7f7;
}

::-webkit-scrollbar {
  width: 12.5px;
}

::-webkit-scrollbar-track {
  background-color: white;
}
 
::-webkit-scrollbar-thumb {
  background: #012876;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0138a7;
}

/* -- Animation Delay's -- */
.anim-delay-0s { animation-delay: 0ms !important; }
.anim-delay-100ms { animation-delay: 0.1s; } .anim-delay-1s-200ms { animation-delay: 1.2s; }
.anim-delay-200ms { animation-delay: 0.2s; } .anim-delay-1s-300ms { animation-delay: 1.3s; }
.anim-delay-300ms { animation-delay: 0.3s; } .anim-delay-1s-400ms { animation-delay: 1.4s; }
.anim-delay-400ms { animation-delay: 0.4s; } .anim-delay-1s-500ms { animation-delay: 1.5s; }
.anim-delay-500ms { animation-delay: 0.5s; } .anim-delay-1s-600ms { animation-delay: 1.6s; }
.anim-delay-600ms { animation-delay: 0.6s; } .anim-delay-1s-700ms { animation-delay: 1.7s; }
.anim-delay-700ms { animation-delay: 0.7s; } .anim-delay-1s-800ms { animation-delay: 1.8s; }
.anim-delay-800ms { animation-delay: 0.8s; } .anim-delay-1s-900ms { animation-delay: 1.9s; }
.anim-delay-900ms { animation-delay: 0.9s; } .anim-delay-2s { animation-delay: 2s; }
.anim-delay-1s { animation-delay: 1s; } .anim-delay-2s-100ms { animation-delay: 2.1s; }
.anim-delay-1s-100ms { animation-delay: 1.1s; } .anim-delay-2s-200ms { animation-delay: 2.2s; }
.anim-delay-2s-300ms { animation-delay: 2.3s; } .anim-delay-3s-300ms { animation-delay: 3.3s; }
.anim-delay-2s-400ms { animation-delay: 2.4s; } .anim-delay-3s-400ms { animation-delay: 3.4s; }
.anim-delay-2s-500ms { animation-delay: 2.5s; } .anim-delay-3s-500ms { animation-delay: 3.5s; }
.anim-delay-2s-600ms { animation-delay: 2.6s; } .anim-delay-3s-600ms { animation-delay: 3.6s; }
.anim-delay-2s-700ms { animation-delay: 2.7s; } .anim-delay-3s-700ms { animation-delay: 3.7s; }
.anim-delay-2s-800ms { animation-delay: 2.8s; } .anim-delay-3s-800ms { animation-delay: 3.8s; }
.anim-delay-2s-900ms { animation-delay: 2.9s; } .anim-delay-3s-900ms { animation-delay: 3.9s; }
.anim-delay-3s { animation-delay: 3s; } .anim-delay-4s { animation-delay: 4s; }
.anim-delay-3s-100ms { animation-delay: 3.1s; } .anim-delay-4s-100ms { animation-delay: 4.1s; }
.anim-delay-3s-200ms { animation-delay: 3.2s; } .anim-delay-4s-200ms { animation-delay: 4.2s; }
.anim-delay-4s-300ms { animation-delay: 4.3s; } .anim-delay-5s-300ms { animation-delay: 5.3s; }
.anim-delay-4s-400ms { animation-delay: 4.4s; } .anim-delay-5s-400ms { animation-delay: 5.4s; }
.anim-delay-4s-500ms { animation-delay: 4.5s; } .anim-delay-5s-500ms { animation-delay: 5.5s; }
.anim-delay-4s-600ms { animation-delay: 4.6s; } .anim-delay-5s-600ms { animation-delay: 5.6s; }
.anim-delay-4s-700ms { animation-delay: 4.7s; } .anim-delay-5s-700ms { animation-delay: 5.7s; }
.anim-delay-4s-800ms { animation-delay: 4.8s; } .anim-delay-5s-800ms { animation-delay: 5.8s; }
.anim-delay-4s-900ms { animation-delay: 4.9s; } .anim-delay-5s-900ms { animation-delay: 5.9s; }
.anim-delay-5s { animation-delay: 5s; } .anim-delay-6s { animation-delay: 6s; }
.anim-delay-5s-100ms { animation-delay: 5.1s; } .anim-delay-6s-100ms { animation-delay: 6.1s; }
.anim-delay-5s-200ms { animation-delay: 5.2s; } .anim-delay-6s-200ms { animation-delay: 6.2s; }
.anim-delay-6s-300ms { animation-delay: 6.3s; } .anim-delay-7s-300ms { animation-delay: 7.3s; }
.anim-delay-6s-400ms { animation-delay: 6.4s; } .anim-delay-7s-400ms { animation-delay: 7.4s; }
.anim-delay-6s-500ms { animation-delay: 6.5s; } .anim-delay-7s-500ms { animation-delay: 7.5s; }
.anim-delay-6s-600ms { animation-delay: 6.6s; } .anim-delay-7s-600ms { animation-delay: 7.6s; }
.anim-delay-6s-700ms { animation-delay: 6.7s; } .anim-delay-7s-700ms { animation-delay: 7.7s; }
.anim-delay-6s-800ms { animation-delay: 6.8s; } .anim-delay-7s-800ms { animation-delay: 7.8s; }
.anim-delay-6s-900ms { animation-delay: 6.9s; } .anim-delay-7s-900ms { animation-delay: 7.9s; }
.anim-delay-7s { animation-delay: 7s; } .anim-delay-8s { animation-delay: 8s; }
.anim-delay-7s-100ms { animation-delay: 7.1s; } .anim-delay-8s-100ms { animation-delay: 8.1s; }
.anim-delay-7s-200ms { animation-delay: 7.2s; } .anim-delay-8s-200ms { animation-delay: 8.2s; }
.anim-delay-9s-300ms { animation-delay: 9.3s; } .anim-delay-10s-300ms { animation-delay: 10.3s; }
.anim-delay-9s-400ms { animation-delay: 9.4s; } .anim-delay-10s-400ms { animation-delay: 10.4s; }
.anim-delay-9s-500ms { animation-delay: 9.5s; } .anim-delay-10s-500ms { animation-delay: 10.5s; }
.anim-delay-9s-600ms { animation-delay: 9.6s; } .anim-delay-10s-600ms { animation-delay: 10.6s; }
.anim-delay-9s-700ms { animation-delay: 9.7s; } .anim-delay-10s-700ms { animation-delay: 10.7s; }
.anim-delay-9s-800ms { animation-delay: 9.8s; } .anim-delay-10s-800ms { animation-delay: 10.8s; }
.anim-delay-9s-900ms { animation-delay: 9.9s; } .anim-delay-10s-900ms { animation-delay: 10.9s; }
.anim-delay-10s { animation-delay: 10s; }
.anim-delay-10s-100ms { animation-delay: 10.1s; }
.anim-delay-10s-200ms { animation-delay: 10.2s; }

/* -- Animation Duration's -- */
.anim-duration-100ms { animation-duration: 0.1s; } .anim-duration-200ms { animation-duration: 0.2s; }
.anim-duration-300ms { animation-duration: 0.3s; } .anim-duration-400ms { animation-duration: 0.4s; }
.anim-duration-500ms { animation-duration: 0.5s; } .anim-duration-600ms { animation-duration: 0.6s; }
.anim-duration-700ms { animation-duration: 0.7s; } .anim-duration-800ms { animation-duration: 0.8s; }
.anim-duration-900ms { animation-duration: 0.9s; } .anim-duration-1s { animation-duration: 1s; }
.anim-duration-1s-500ms { animation-duration: 1s; } .anim-duration-2s { animation-duration: 2s; }
.anim-duration-2s-500ms { animation-duration: 2s; } .anim-duration-3s { animation-duration: 3s; }
.anim-duration-3s-500ms { animation-duration: 3s; } .anim-duration-4s { animation-duration: 4s; }
.anim-duration-4s-500ms { animation-duration: 4s; } .anim-duration-5s { animation-duration: 5s; }
.anim-duration-5s-500ms { animation-duration: 5s; } .anim-duration-6s { animation-duration: 6s; }
.anim-duration-6s-500ms { animation-duration: 6s; } .anim-duration-7s { animation-duration: 7s; }
.anim-duration-7s-500ms { animation-duration: 7s; } .anim-duration-8s { animation-duration: 8s; }
.anim-duration-8s-500ms { animation-duration: 8s; } .anim-duration-9s { animation-duration: 9s; }
.anim-duration-9s-500ms { animation-duration: 9s; } .anim-duration-10s { animation-duration: 10s; }
.anim-duration-10s-500ms { animation-duration: 10s; } .anim-duration-11s { animation-duration: 11s; }
.anim-duration-11s-500ms { animation-duration: 11s; } .anim-duration-12s { animation-duration: 12s; }
.anim-duration-12s-500ms { animation-duration: 12s; } .anim-duration-13s { animation-duration: 13s; }
.anim-duration-13s-500ms { animation-duration: 13s; } .anim-duration-14s { animation-duration: 14s; }
.anim-duration-14s-500ms { animation-duration: 14s; } .anim-duration-15s { animation-duration: 15s; }
.anim-duration-15s-500ms { animation-duration: 15s; } .anim-duration-16s { animation-duration: 16s; }
.anim-duration-16s-500ms { animation-duration: 16s; } .anim-duration-17s { animation-duration: 17s; }
.anim-duration-17s-500ms { animation-duration: 17s; } .anim-duration-18s { animation-duration: 18s; }
.anim-duration-18s-500ms { animation-duration: 18s; } .anim-duration-19s { animation-duration: 19s; }
.anim-duration-19s-500ms { animation-duration: 19s; } .anim-duration-20s { animation-duration: 20s; }
.anim-duration-20s-500ms { animation-duration: 20s; }