@keyframes anim_A {
    0% { transform: translate(0px, 0px) scale(1); }
    20% { transform: translate(25px, 0px) scale(1.4); }
    40% { transform: translate(25px, 25px) scale(1); }
    80% { transform: translate(0px, 25px) scale(1.4); }
    100% { transform: translate(0px, 0px) scale(1); }
}

@keyframes anim_B {
    0% { -webkit-transform: rotate(0deg) translate(0px, 0px) rotate(0deg); }
    25% { -webkit-transform: rotate(90deg) translate(6px , 0px) rotate(-90deg); }
    50% { -webkit-transform: rotate(180deg) translate(6px, 6px) rotate(-180deg); }
    75% { -webkit-transform: rotate(270deg) translate(0px, 6px) rotate(-270deg); }
    100% { -webkit-transform: rotate(360deg) translate(0px, 0px) rotate(-360deg); }
}

.grecaptcha-badge {
    display: none;
}

/* desktop */
@media only screen and (min-width: 1000px) {

.up-scroll-btn {
    width: 120px;
    color: var(--primary);
    z-index: 10;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
    transition: transform 0.1s linear;

    &:hover {
        filter: brightness(120%);
        transform: scale(1.1);
    }

    &:focus, &:active {
        -webkit-tap-highlight-color: transparent;
    }
}

/* -------------------------------------------- */
/*------------------- HEADER -------------------*/
/* -------------------------------------------- */
header {
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

header img {
    width: 280px;
    margin-right: calc(15vw - 100px);
}

header div {
    display: flex;
}

header div a {
    text-decoration: none;
    margin-right: 10px;
    margin-left: 10px;
    padding: 10px 25px;
    cursor: pointer;
    &:hover {
        background-color: var(--primary);
        filter: brightness(1.4);
    }
}

header div a p {
    color: white;
    font-size: 11pt;
}
/* -------------------------------------------- */
/* -------------------------------------------- */
/* -------------------------------------------- */

/* -------------------------------------------- */
/*-------------------- HOME --------------------*/
/* -------------------------------------------- */
#home {
    width: unset;
    height: 600px;
    background-image: url("./images/homeBG.png");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: top;
    background-size: cover;
}

#home .blueFilter {
    width: 100%;
    height: 600px;
    background-color: rgba(0, 35, 103, 0.5);
    position: relative;
    right: 0;
    z-index: 1;
    top: -600px;
    overflow: hidden;
}

#home .blurFilter {
    width: calc(100px + 40vw);
    height: 600px;
    position: relative;
    z-index: 2;
    filter: blur(8px);
    background-image: url("./images/homeBG.png");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: top;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
}

#home .blueFilter div {
    width: calc((100vw - (100px + 40vw)) - (0px + 15vw));
    position: relative;
    left: calc((100vw - (100vw - (100px + 40vw))) + 50px);
    top: 50%;
    transform: translateY(-50%);
    color: white;
    overflow: hidden;
}

#home .blueFilter div h1 {
    font-size: 30pt;
    margin-bottom: 20px;
}

#home .blueFilter div p {
    font-size: 12pt;
    text-align: center;
    margin-bottom: 15px;
}

#home .blueFilter div a {
    float: right;
    padding: 10px 40px;
    border: none;
    cursor: pointer;
    background-color: var(--primary);
    color: white;
    font-size: 11pt;
    text-decoration: none;
    clip-path: polygon(12% 0, 100% 0%, 88% 100%, 0% 100%);
    &:hover {
        filter: brightness(1.5);
    }
}

#home .anim-home {
    position: absolute;
    z-index: 3;
    width: calc(70px + 10vw);
}

#home .anim-home-start {
    animation-name: anim_B;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

#home #ah-1 {
    top: 125px;
    left: calc((100px + 40vw) / 5);
}

#home #ah-2 {
    top: 90px;
    left: calc((100px + 40vw) / 1.3);
}

#home #ah-3 {
    top: 300px;
    left: 0;
}

#home #ah-4 {
    top: 300px;
    left: calc((100px + 40vw) / 2.1);
}

#home #ah-5 {
    top: 450px;
    left: calc((100px + 40vw) / 7);
}

#home #ah-6 {
    top: 470px;
    left: calc((100px + 40vw) / 1.8);
}
/* -------------------------------------------- */
/* -------------------------------------------- */
/* -------------------------------------------- */

/* -------------------------------------------- */
/*-------------------- ABOUT --------------------*/
/* -------------------------------------------- */
#about {
    display: flex;
    overflow: hidden !important;
}

#about .left {
    width: 50vw;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#about .anim-side-image-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}

#about .image-container {
    width: calc(100% - 50px);
    height: calc((30px + 20vw));
    clip-path: polygon(20% 0, 100% 0%, 80% 100%, 0% 100%);
    z-index: 7;
    background-image: url("./images/about.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#about .bg-element {
    width: calc(25% - 20px);
    height: calc(30px + 20vw);
    background-color: var(--secondary);
    margin-right: 21.3%;
    clip-path: polygon(40% 0, 100% 0%, 60% 100%, 0% 100%);
    transform: scale(1.2);
    position: absolute;
}

#about #bge-2 {
    margin-right: 5%;
    opacity: 0.2 !important;
}

#about #ae-1 {
    width: calc(12.5% - 10px);
    height: calc(15px + 10vw);
    background-color: var(--secondary);
    opacity: 0.2 !important;
    margin-right: 34%;
    margin-top: -14%;
    clip-path: polygon(40% 0, 100% 0%, 60% 100%, 0% 100%);
    transform: scale(1.2);
    position: absolute;
    z-index: 8;
}

#about #ae-2 {
    width: calc(12.5% - 10px);
    height: calc(15px + 10vw);
    background-color: var(--secondary);
    opacity: 0.2 !important;
    margin-right: 2%;
    margin-top: 11%;
    clip-path: polygon(40% 0, 100% 0%, 60% 100%, 0% 100%);
    transform: scale(1.2);
    position: absolute;
    z-index: 8;
}

#about #ae-3 {
    width: calc(12.5% - 10px);
    height: calc(15px + 4vw);
    background-color: var(--secondary);
    opacity: 0.2 !important;
    margin-right: 0%;
    margin-top: 0%;
    clip-path: polygon(18% 0, 100% 0%, 82% 100%, 0% 100%);
    transform: scale(1.2);
    position: absolute;
    z-index: 1;
}

#about #ae-4 {
    width: calc(12.5% - 10px);
    height: calc(15px + 4vw);
    background-color: var(--secondary);
    opacity: 0.2 !important;
    margin-right: 34%;
    margin-top: -1%;
    clip-path: polygon(18% 0, 100% 0%, 82% 100%, 0% 100%);
    transform: scale(1.2);
    position: absolute;
    z-index: 9;
}

#about .anim-element {
    animation-name: anim_A;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

#about .right {
    width: 50vw;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#about .right img {
    width: 200px;
}

#about .right h2 {
    color: var(--primary);
    font-size: 19pt;
    & b {
        font-size: 28pt;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 0px;
    }
}

#about .right p {
    padding: 60px;
    text-align: justify;
    color: var(--text);
    font-size: 11.5pt;
}
/* -------------------------------------------- */
/* -------------------------------------------- */
/* -------------------------------------------- */

/* -------------------------------------------- */
/*------------------- BRANDS -------------------*/
/* -------------------------------------------- */
#brands {
    overflow: hidden;
    height: 600px;
}

#brands .title {
    width: 100%;
    background-color: var(--primary);
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 60px;
    padding-left: 60px;
    color: white;
}

#brands .title h1 {
    font-size: 21pt;
}

#brands .title p {
    font-size: 11pt;
}

#brands .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#brands .row img {
    width: 140px;
    margin: 50px;
}
/* -------------------------------------------- */
/* -------------------------------------------- */
/* -------------------------------------------- */

/* -------------------------------------------- */
/*------------------- CONTACT ------------------*/
/* -------------------------------------------- */
#contact {
    width: 100%;
    height: 600px;
    background-color: var(--background-gray);
    display: flex;
}

#contact .left {
    width: 40vw;
    padding: 60px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

#contact .left .input {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 11pt;
    color: var(--text);

    & label {
        font-weight: 600;
    }

    & input {
        background-color: transparent;
        border: none;
        border-bottom: 2px var(--text) solid;
        font-size: 11pt;
        padding: 7px;
        padding-left: 0px;
        color: var(--text);
    }

    & input:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
        border-color: rgb(0, 76, 227);
    }

    & textarea {
        background-color: transparent;
        border: none;
        border-bottom: 2px var(--text) solid;
        font-size: 11pt;
        padding: 7px;
        padding-left: 0px;
        color: var(--text);
        resize: none;
        height: 60px;
    }

    & textarea:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
        border-color: rgb(0, 76, 227);
    }
}

#contact .left button {
    margin-top: 20px;
    float: right;
    padding: 10px 40px;
    border: none;
    cursor: pointer;
    background-color: var(--primary);
    color: white;
    font-size: 11pt;
    clip-path: polygon(12% 0, 100% 0%, 88% 100%, 0% 100%);
    &:hover {
        filter: brightness(1.5);
    }
}

#contact .right {
    width: 60vw;
    background-image: url("./images/contact.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    clip-path: polygon(27% 0%, 100% 0, 100% 100%, 0% 100%);
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    color: white;
    padding-right: 60px;

    & h1 {
        margin-bottom: 20px;
        font-size: 28pt;
    }

    & p {
        text-align: end;
        width: 40vw;
        margin-top: 15px;
        margin-bottom: 15px;

        & b {
            margin-right: 10px;
        }
    }
}

#contact .right div img {
    width: 50px;
    margin-left: 20px;
}
/* -------------------------------------------- */
/* -------------------------------------------- */
/* -------------------------------------------- */

/* -------------------------------------------- */
/*------------------- FOOTER -------------------*/
/* -------------------------------------------- */
footer {
    background-color: var(--primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;

    & p {
        font-size: 10pt;
        padding-left: 10vw;
        padding-right: 10vw;

        & b {
            font-weight: 300;
            font-size: 8pt;
            float: right;
        }

        & a {
            color: white;
        }
    }

    & img {
        width: 230px;
        padding-left: 10vw;
        padding-right: 10vw;
    }
}
/* -------------------------------------------- */
/* -------------------------------------------- */
/* -------------------------------------------- */

}

/*|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|*/
/*|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|*/
/*|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|*/
/*|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|*/
/*|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|*/
/*|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|*/

/* mobile */
@media only screen and (max-width: 1000px) {
    html {
        width: 100vw;
        overflow-x: hidden;
    }

    .up-scroll-btn {
        width: 90px;
        color: var(--primary);
        z-index: 10;
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        cursor: pointer;
        transition: transform 0.1s linear;
    
        &:hover {
            filter: brightness(120%);
            transform: scale(1.1);
        }
    
        &:focus, &:active {
            -webkit-tap-highlight-color: transparent;
        }
    }
    
    /* -------------------------------------------- */
    /*------------------- HEADER -------------------*/
    /* -------------------------------------------- */
    header {
        background-color: var(--primary);
        padding-top: 12px;
        padding-bottom: 12px;
        overflow: hidden;
        text-align: center;
        height: 60px;
        transition: height 0.3s linear;
        & .menu-button {
            color: white;
            font-size: 24pt;
            position: absolute;
            top: 27px;
            right: 25px;
            display: block !important;
            cursor: pointer;
        }
    }

    .header-exp {height: 220px;}
    
    header img {
        margin-right: unset;
        width: 200px;
    }
    
    header div {
        flex-direction: column;
        padding-top: 10px;
    }
    
    header div a {
        text-decoration: none;
        margin-right: 10px;
        margin-left: 10px;
        padding: 10px 25px;
        cursor: pointer;
        text-align: center;

        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;

        &:focus, &:active {
            -webkit-tap-highlight-color: transparent;
        }
    }
    
    header div a p {
        color: white;
        font-size: 11pt;

        &:focus, &:active {
            -webkit-tap-highlight-color: transparent;
        }
    }
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    
    /* -------------------------------------------- */
    /*-------------------- HOME --------------------*/
    /* -------------------------------------------- */
    #home {
        width: unset;
        height: calc(100vh - 124px);
        background-image: url("./images/homeBG.png");
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: top;
        background-size: cover;
    }
    
    #home .blueFilter {
        width: 100%;
        height: calc(100vh - 124px);
        background-color: rgba(0, 35, 103, 0.5);
        position: relative;
        right: 0;
        z-index: 1;
        overflow: hidden !important;
    }
    
    #home .blurFilter {
        display: none;
    }
    
    #home .blueFilter div {
        width: 350px;
        margin: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        color: white;
        overflow: hidden;
    }
    
    #home .blueFilter div h1 {
        font-size: 24pt;
        margin-bottom: 15px;
    }
    
    #home .blueFilter div p {
        font-size: 11pt;
        text-align: center;
        margin-bottom: 25px;
    }
    
    #home .blueFilter div a {
        float: right;
        padding: 10px 40px;
        border: none;
        cursor: pointer;
        background-color: var(--primary);
        color: white;
        font-size: 11.5pt;
        text-decoration: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        clip-path: polygon(12% 0, 100% 0%, 88% 100%, 0% 100%);
        &:hover {
            filter: brightness(1.5);
        }
    }
    
    #home .anim-home {
        display: none;
    }
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    
    /* -------------------------------------------- */
    /*-------------------- ABOUT --------------------*/
    /* -------------------------------------------- */
    #about {
        display: flex;
        flex-direction: column;
        overflow: hidden !important;
        padding-bottom: 20px;
    }
    
    #about .left {
        width: 100vw;
        overflow: hidden !important;
        height: calc(((30px + 20vw) * 2.2) + 200px);
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(2.2) translateX(-4%);
    }
    
    #about .anim-side-image-container {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: end;
    }
    
    #about .image-container {
        width: calc(100% - 50px);
        height: calc((30px + 20vw));
        clip-path: polygon(20% 0, 100% 0%, 80% 100%, 0% 100%);
        z-index: 7;
        background-image: url("./images/about.jpeg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    
    #about .bg-element {
        width: calc(25% - 20px);
        height: calc(30px + 20vw);
        background-color: var(--secondary);
        margin-right: 21.3%;
        clip-path: polygon(40% 0, 100% 0%, 60% 100%, 0% 100%);
        transform: scale(1.2);
        position: absolute;
    }
    
    #about #bge-2 {
        margin-right: 5%;
        opacity: 0.2 !important;
    }
    
    #about #ae-1 {
        width: calc(12.5% - 10px);
        height: calc(15px + 10vw);
        background-color: var(--secondary);
        opacity: 0.2 !important;
        margin-right: 34%;
        margin-top: -14%;
        clip-path: polygon(40% 0, 100% 0%, 60% 100%, 0% 100%);
        transform: scale(1.2);
        position: absolute;
        z-index: 8;
    }
    
    #about #ae-2 {
        width: calc(12.5% - 10px);
        height: calc(15px + 10vw);
        background-color: var(--secondary);
        opacity: 0.2 !important;
        margin-right: 2%;
        margin-top: 11%;
        clip-path: polygon(40% 0, 100% 0%, 60% 100%, 0% 100%);
        transform: scale(1.2);
        position: absolute;
        z-index: 8;
    }
    
    #about #ae-3 {
        width: calc(12.5% - 10px);
        height: calc(15px + 4vw);
        background-color: var(--secondary);
        opacity: 0.2 !important;
        margin-right: 0%;
        margin-top: 0%;
        clip-path: polygon(18% 0, 100% 0%, 82% 100%, 0% 100%);
        transform: scale(1.2);
        position: absolute;
        z-index: 1;
    }
    
    #about #ae-4 {
        width: calc(12.5% - 10px);
        height: calc(15px + 4vw);
        background-color: var(--secondary);
        opacity: 0.2 !important;
        margin-right: 34%;
        margin-top: -1%;
        clip-path: polygon(18% 0, 100% 0%, 82% 100%, 0% 100%);
        transform: scale(1.2);
        position: absolute;
        z-index: 9;
    }
    
    #about .anim-element {
        animation-name: anim_A;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    
    #about .right {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    #about .right img {
        width: 200px;
        margin: auto;
    }
    
    #about .right h2 {
        color: var(--primary);
        margin: auto;
        font-size: 19pt;
        & b {
            font-size: 28pt;
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 0px;
        }
    }
    
    #about .right p {
        padding: 30px;
        text-align: justify;
        color: var(--text);
        font-size: 11.5pt;
    }
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    
    /* -------------------------------------------- */
    /*------------------- BRANDS -------------------*/
    /* -------------------------------------------- */
    #brands {
        overflow: hidden;
        padding-bottom: 30px;
    }
    
    #brands .title {
        width: calc(100vw - 60px);
        background-color: var(--primary);
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 30px;
        padding-left: 30px;
        color: white;
        overflow: hidden;
    }
    
    #brands .title h1 {
        font-size: 21pt;
    }
    
    #brands .title p {
        font-size: 11pt;
    }
    
    #brands .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    #brands .row img {
        width: 110px;
        margin: 20px;
    }
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    
    /* -------------------------------------------- */
    /*------------------- CONTACT ------------------*/
    /* -------------------------------------------- */
    #contact {
        width: 100vw;
        background-color: var(--background-gray);
        display: flex;
        flex-direction: column-reverse;
    }
    
    #contact .left {
        width: calc(100vw - 60px);
        padding: 30px;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    
    #contact .left .input {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        margin-bottom: 25px;
        font-size: 11pt;
        color: var(--text);
    
        & label {
            font-weight: 600;
        }
    
        & input {
            background-color: transparent;
            border: none;
            border-bottom: 2px var(--text) solid;
            font-size: 11pt;
            padding: 7px;
            padding-left: 0px;
            color: var(--text);
        }
    
        & input:focus {
            border-color: inherit;
            -webkit-box-shadow: none;
            box-shadow: none;
            outline: none;
            border-color: rgb(0, 76, 227);
        }
    
        & textarea {
            background-color: transparent;
            border: none;
            border-bottom: 2px var(--text) solid;
            font-size: 11pt;
            padding: 7px;
            padding-left: 0px;
            color: var(--text);
            resize: none;
            height: 60px;
        }
    
        & textarea:focus {
            border-color: inherit;
            -webkit-box-shadow: none;
            box-shadow: none;
            outline: none;
            border-color: rgb(0, 76, 227);
        }
    }
    
    #contact .left button {
        margin-top: 20px;
        float: right;
        padding: 12px 50px;
        border: none;
        cursor: pointer;
        margin-bottom: 15px;
        background-color: var(--primary);
        color: white;
        font-size: 11.5pt;
        clip-path: polygon(12% 0, 100% 0%, 88% 100%, 0% 100%);
        &:hover {
            filter: brightness(1.5);
        }
    }
    
    #contact .right {
        width: 100vw;
        background-image: url("./images/contact.jpeg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        padding-top: 30px;
        padding-bottom: 30px;
    
        & h1 {
            margin-bottom: 20px;
            font-size: 24pt;
        }
    
        & p {
            text-align: justify;
            width: calc(100vw - 60px);
            margin-top: 15px;
            margin-bottom: 15px;
            padding-left: 30px;
            padding-right: 30px;
    
            & b {
                margin-right: 10px;
            }
        }
    }
    
    #contact .right div img {
        margin-top: 20px;
        width: 45px;
        margin-left: 20px;
    }
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    
    /* -------------------------------------------- */
    /*------------------- FOOTER -------------------*/
    /* -------------------------------------------- */
    footer {
        background-color: var(--primary);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100vw - 15px);
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 5px;
    
        & p {
            font-size: 8pt;
            text-align: center;
            margin-right: 1vw;
    
            & b {
                font-weight: 300;
                font-size: 6.5pt;
            }
    
            & a {
                color: white;
            }
        }
    
        & img {
            width: 200px;
            margin-left: 1vw;
        }
    }
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    /* -------------------------------------------- */
    
    }